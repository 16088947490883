import { clientOnly } from "@solidjs/start";
import { utmStoreGet } from "~/lib/utm_store";
import CommonFirstScreenImage from "~/components/shared/CommonFirstScreenImage";

import "~/components/shared/CommonPageStyle.css";
import "~/components/shared/CommonIntroduction.css";
import "~/components/shared/ContentSEOText.css";
import "./SimulatorPage.css";
import { useSearchParams } from "@solidjs/router";

const SimulatorLmnp = clientOnly(() => import("../Forms/SimulatorLmnp"));

export default function LmnpPage() {
  const [searchParams] = useSearchParams();
  const hiddenFields = () => {
    return {
      base_email: utmStoreGet("utm_source"),
      campaign: utmStoreGet("utm_campaign"),
      source: utmStoreGet("utm_source"),
      medium: utmStoreGet("utm_medium"),
      term: utmStoreGet("utm_term"),
      content: utmStoreGet("utm_content"),
      lot_id: searchParams.lot_id,
      program_nid: searchParams.program_id,
    };
  };

  return (
    <>
      <article class="common-page-style simulator-page simulator-lmnp">
        <CommonFirstScreenImage image="/images/simulator-lmnp.jpg" alt="" />

        <div class="outer-content-area" id="top">
          <section class="content-area">
            <div class="inner">
              <SimulatorLmnp
                hiddenFields={{ form_id: "simulator-lmnp", ...hiddenFields() }}
                defaultValues={{
                  SFamille: "1",
                  NbEnfant: "0",
                  Apport: "0",
                  ParticipationMax: "1000",
                  TauxLoyer1: "4,00",
                  PretNbAn: "25",
                  PretTaux: "2,00",
                  TauxAdi: "0,35",
                  TauxCharge: "4",
                  TauxRevalLoyer: "1,00",
                  PartMobilier: "4,00",
                  TauxValorBien: "1,00",
                }}
              />
            </div>
          </section>
        </div>

        <LmnpSeoText />
      </article>
    </>
  );
}

function LmnpSeoText() {
  return (
    <>
      <section class="content-seo-text" data-test="seo-block">
        <div class="block">
          <h2>Comment utiliser le simulateur LMNP</h2>
          <p>
            La simulation de votre investissement en LMNP est simple, gratuite,
            et sans engagement sur cogedim.com. Voici les informations à
            renseigner dans le formulaire du simulateur&nbsp;:
          </p>
          <h3>
            Pour simuler votre investissement à partir de votre capacité
            d’épargne
          </h3>
          <ol>
            <li>Indiquez votre situation familiale</li>
            <li>Précisez le nombre d’enfants à votre charge</li>
            <li>Renseignez votre revenu annuel net imposable</li>
            <li>Indiquez le montant de votre apport personnel</li>
            <li>Estimez votre capacité d’épargne mensuelle</li>
          </ol>
          <h3>
            Pour simuler votre investissement à partir du prix de votre futur
            logement
          </h3>
          <ol>
            <li>Indiquez votre situation familiale</li>
            <li>Précisez le nombre d’enfants à votre charge</li>
            <li>Renseignez votre revenu annuel net imposable</li>
            <li>Indiquez le montant de votre apport personnel</li>
            <li>Précisez le prix TTC de votre futur logement</li>
            <li>Indiquez également le prix TTC du mobilier</li>
          </ol>
          <h3>
            Pour effectuer votre simulation LMNP avec un maximum de précision,
            vous avez la possibilité de paramétrer des options complémentaires
            dans le simulateur&nbsp;:
          </h3>
          <ul>
            <li>Le taux annuel de rendement locatif</li>
            <li>La durée et le taux de votre prêt</li>
            <li>Le taux de l’assurance</li>
            <li>Les frais de gestion</li>
            <li>Le taux de revalorisation annuel du loyer</li>
            <li>Le taux de revalorisation annuel de la valeur du logement</li>
          </ul>
        </div>
        <div class="block">
          <h2>
            Quels sont les bénéfices de la location meublée avec le statut LMNP
          </h2>
          <h3>Des avantages fiscaux</h3>
          <h4>
            Profitez d’un battement forfaitaire de 50% avec le régime Micro-BIC
          </h4>
          <p>
            Le statut LMNP vous permet de profiter d’une fiscalité avantageuse
            car les bénéfices générés par la location de votre bien sont soumis
            au régime des Bénéfices Industriels et Commerciaux (BIC) qui permet
            de profiter d’un abattement forfaitaire de 50%.
          </p>
          <h4>Déduisez vos charges avec le régime Réel</h4>
          <p>
            Autre option, le régime dit "Réel" permet de déduire les charges
            liées à votre bien comme la taxe foncière, les frais d’assurances ou
            encore les charges locatives ! Il vous permet également d’amortir
            l’intégralité du mobilier. Vous pourrez alors réduire, voir même
            annuler votre impôt sur le revenu.
          </p>
          <h4>Récupérez la TVA</h4>
          <p>
            De plus, le statut LMNP vous offre la possibilité de récupérer la
            TVA sur votre investissement dans le cadre d’une résidence services
            neuve. Découvrez sans plus attendre nos résidences étudiantes et nos
            résidences senior afin de bénéficier de tous ces avantages !
          </p>
          <h4>Une gestion simplifiée</h4>
          <p>
            Choisir d’investir dans l’immobilier avec le statut LMNP c’est faire
            le choix de se décharger de toute la gestion locative qui est
            confiée à un gestionnaire professionnel. Oubliez l’organisation des
            visites, le choix des locataires, les états de lieux d’entrée et de
            sortie … votre gestionnaire s’occupe de tout !
          </p>
          <p>
            De plus cela vous offre la garantie de percevoir vos loyers même en
            cas de vacance locative ! En effet, le bailleur commercial est tenu
            de vous verser vos loyers même si le logement est vide.
          </p>
          <h4>Une meilleure rentabilité brute</h4>
          <p>
            Louer un logement déjà meublé permet de percevoir des loyers plus
            importants, jusqu’à 30% plus élevés que pour une location vide d’un
            logement aux caractéristiques similaires. Pour cela, nul besoin
            d’investir dans un mobilier luxueux, vous pouvez simplement équiper
            votre logement des éléments indispensables pour louer meublé.
          </p>
        </div>
        <div class="block">
          <h2>A quoi correspondent les frais de notaire ?</h2>
          <p>
            La simulation de votre investissement LMNP vous indique une
            estimation de vos frais de notaire. Ces frais ne correspondent pas
            réellement aux honoraires du notaire, mais ce dernier est chargé de
            les recevoir. En effet, seuls les émoluments du notaire
            correspondent à sa rémunération. Pour un logement neuf, les frais de
            notaire représentent 2 à 3% du prix du bien, contre 8% pour un bien
            ancien.
          </p>
          <p>Les frais de notaire se composent ainsi :</p>
          <ul>
            <li>La taxe de publicité foncière</li>
            <li>Les honoraires du notaire</li>
            <li>Les émoluments de formalités</li>
            <li>Les frais divers</li>
            <li>La contribution de sécurité immobilière</li>
          </ul>
        </div>
      </section>
    </>
  );
}
