import { AccessBudget } from "~/components/Forms/Fields/AccessBudget";
import { Select } from "~/components/Forms/Fields/Base/Select";
import {
  createMemo,
  createRenderEffect,
  createResource,
  createSignal,
  For,
  Show,
  Suspense,
} from "solid-js";
import { DebugDumper } from "~/components/Forms/Utils/DebugDumper";
import type {
  Landing,
  LotAccessMonthlySearchResult,
} from "~/types/drupal_jsonapi";
import { Pagination, paginationSlices } from "~/components/shared/Pagination";
import { createStore } from "solid-js/store";
import { useSearchParams } from "@solidjs/router";
import type {
  LotAccessMonthlySearchResultResponse,
  SubmissionValues,
} from "~/types/common";
import { getAccessGeogaphies, getWrapper } from "~/lib/fetcher";
import { toDashedSafe } from "~/utils/tools";
import { debounce } from "@solid-primitives/scheduled";
import {
  submitAccessSearchCountForm,
  submitAccessSearchLotsForm,
} from "~/components/Search/server";
import {
  formatFloor,
  formatPlural,
  formatPrice,
  formatSurface,
} from "~/utils/format";
import {
  getCheapestPriceGroup,
  getOutdoorsForTypology,
  getPricesAndReturnRates,
} from "~/utils/helper_lot";
import SeoText from "~/components/CogedimAccess/Components/SeoText";
import { useLotActive } from "~/contexts/LotActiveContext";
import LotDrawer from "~/components/Program/Components/LotDrawer";
import { useEventsContext } from "~/contexts/EventsContext";
import { useModalFormContext } from "~/contexts/ModalFormContext";

import "./CogedimAccessMonthlySearchPage.css";
import type { BreadcrumbItem } from "~/types/breadcrumb";
import { urlRs } from "~/utils/url";
import Breadcrumb from "~/components/shared/Breadcrumb";
import { clientOnly } from "@solidjs/start";
import { utmStoreGet } from "~/lib/utm_store";
import { getFormEventNameFromCrmObject } from "../Program/Components/ProgramFormInPage";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { getDataAtPath } from "~/lib/fetcherInternal";

import "~/components/Landing/Components/LandingFormInPage.css";
import FirstScreen from "~/components/CogedimAccess/Components/FirstScreen";
import SeoTextBis from "~/components/CogedimAccess/Components/SeoTextBis";
import LandingPrograms from "~/components/Landing/Components/LandingPrograms";
import GtmVars from "../shared/Trackers/GtmVars";
import SeoTextIntro from "~/components/CogedimAccess/Components/SeoTextIntro";

const FormInPage = clientOnly(() => import("../../components/Forms/InPage"));

type LotsAccessSearchPaginationStore = {
  sort:
    | "program_name"
    | "postal_code"
    | "city"
    | "rooms"
    | "access_mp_amount_ascending"
    | "access_mp_amount_descending";
  currentPage: number;
  results: LotAccessMonthlySearchResult[];
  slices: LotAccessMonthlySearchResult[][];
  displayedLots: LotAccessMonthlySearchResult[];
};

const debug = false;

const [store, setStore] = createStore<LotsAccessSearchPaginationStore>({
  sort: "postal_code",
  currentPage: 0,
  results: [],
  slices: [],
  displayedLots: [],
});

export default function CogedimAccessMonthlySearchPage() {
  const settings = useDrupalSettingsContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [lotsSearchParams, setLotsSearchParams] = createStore<SubmissionValues>(
    {},
  );
  const [data] = createResource(getAccessGeogaphies);
  const [landing] = createResource(
    () => settings.landing_access_nid,
    (id) => getDataAtPath<Landing>(`solid/landing/${id}`),
  );

  const canShowLanding = () => {
    return landing() && landing()?.field_form;
  };

  const [lotProvider] = useLotActive();

  // Re-init page params
  setSearchParams({ page: undefined });

  createRenderEffect(() => {
    const [, { setTemplate }] = useEventsContext();
    setTemplate("search-mp-access");
  });

  const sortFn = (
    a: LotAccessMonthlySearchResult,
    b: LotAccessMonthlySearchResult,
  ) => {
    switch (store.sort) {
      case "program_name":
      default:
        return (
          a.program_name.localeCompare(b.program_name) ||
          a.rooms - b.rooms ||
          a.field_access_mp_amount - b.field_access_mp_amount
        );
      case "postal_code":
        return (
          a.postal_code.localeCompare(b.postal_code) ||
          a.program_name.localeCompare(b.program_name) ||
          a.rooms - b.rooms ||
          a.field_access_mp_amount - b.field_access_mp_amount
        );
      case "city":
        return (
          a.geography_city.localeCompare(b.geography_city) ||
          a.program_name.localeCompare(b.program_name) ||
          a.rooms - b.rooms ||
          a.field_access_mp_amount - b.field_access_mp_amount
        );
      case "rooms":
        return (
          a.rooms - b.rooms ||
          a.field_access_mp_amount - b.field_access_mp_amount ||
          a.program_name.localeCompare(b.program_name)
        );
      case "access_mp_amount_ascending":
        return (
          a.field_access_mp_amount - b.field_access_mp_amount ||
          b.rooms - a.rooms || // reverse order
          a.program_name.localeCompare(b.program_name)
        );
      case "access_mp_amount_descending":
        return (
          b.field_access_mp_amount - a.field_access_mp_amount ||
          b.rooms - a.rooms || // reverse order
          a.geography_city.localeCompare(b.geography_city)
        );
    }
  };

  const optionsGeographies = () => {
    if (!data()) {
      return [];
    }

    return data()!.geographies.map((geo) => ({
      label: `${geo.name} (${geo.postal_code ? geo.postal_code.substring(0, 2) : ""})`,
      value: `d-${geo.name}`,
      name: toDashedSafe(geo.name),
      children: geo.cities.map((city) => ({
        label: city.name,
        value: `c-${city.name}`,
        name: toDashedSafe(city.name),
      })),
    }));
  };

  const optionsRooms = [
    {
      label: "1 pièce",
      value: "1",
      name: "rooms-1",
    },
    {
      label: "2 pièces",
      value: "2",
      name: "rooms-2",
    },
    {
      label: "3 pièces",
      value: "3",
      name: "rooms-3",
    },
    {
      label: "4 pièces",
      value: "4",
      name: "rooms-4",
    },
    {
      label: "5 pièces et +",
      value: "5",
      name: "rooms-5",
    },
  ];

  const [count, setCount] = createSignal<undefined | number>(undefined);

  const debouncedCount = debounce(async (store: SubmissionValues) => {
    const c = await submitAccessSearchCountForm(store);
    debug && console.log(c);
    // @ts-expect-error TODO: fix this
    setCount(c.data.count);
  }, 500);

  const getLots = async (
    e: MouseEvent & {
      currentTarget: HTMLButtonElement;
      target: Element;
    },
  ) => {
    e.preventDefault();
    const r =
      await submitAccessSearchLotsForm<LotAccessMonthlySearchResultResponse>(
        lotsSearchParams,
      );
    debug && console.log(r);
    if ("data" in r) {
      setStore("results", r.data.lots as LotAccessMonthlySearchResult[]);
    }
  };

  createRenderEffect(() => {
    setStore("currentPage", parseInt(searchParams.page || "0"));
  });

  createRenderEffect(() => {
    setStore(
      "sort",
      (searchParams.sort as LotsAccessSearchPaginationStore["sort"]) ||
        "postal_code",
    );
  });

  createRenderEffect(() => {
    const slices = paginationSlices(store.results!.toSorted(sortFn));
    setStore("slices", slices);
  });

  createRenderEffect(() => {
    if (store.slices[store.currentPage]) {
      setStore(
        "displayedLots",
        store.slices[store.currentPage].filter(Boolean),
      );
    } else {
      setStore("displayedLots", []);
    }
  });

  const breadcrumbItems = createMemo(() => {
    const items: BreadcrumbItem[] = [];

    const root: BreadcrumbItem = {
      text: "Cogedim pour vous",
    };
    items.push(root);

    const breadCrumbLinks: BreadcrumbItem[] = [
      {
        href: urlRs("journeys", "/cogedim-pour-vous/pourquoi-choisir-cogedim/"),
        text: "Pourquoi choisir Cogedim",
      },
      {
        href: urlRs(
          "journeys",
          "/cogedim-pour-vous/pourquoi-choisir-cogedim/le-nouveau-neuf/",
        ),
        text: "Le nouveau neuf",
      },
    ];

    breadCrumbLinks.forEach((item) => items.push(item));

    const lastBreadCrumbItem: BreadcrumbItem = {
      text: "Solution Cogedim Access",
    };
    items.push(lastBreadCrumbItem);

    return items;
  });

  const gtmValues = () => {
    return {
      "all.pageType": "other",
      "all.mainCategory": breadcrumbItems().at(0)?.text ?? "Non défini",
      "all.category": breadcrumbItems().at(1)?.text ?? "Non défini",
      "all.subCategory": breadcrumbItems().at(2)?.text ?? "Non défini",
      "all.subsubCategory": breadcrumbItems().at(3)?.text ?? "Non défini",
      "all.subsubsubCategory": breadcrumbItems().at(4)?.text ?? "Non défini",
      template: "cogedim-access",
    };
  };

  return (
    <>
      <GtmVars values={gtmValues()} />
      <Show when={debug}>
        <DebugDumper
          name="Store"
          data={{
            store,
          }}
        />
      </Show>

      <article class="cogedim-access-monthly-search">
        <Show when={lotProvider.wrapper && lotProvider.lot} keyed>
          <Suspense>
            <LotDrawer
              wrapper={lotProvider.wrapper!}
              lot={lotProvider.lot!}
              isNotFromProgram
            />
          </Suspense>
        </Show>

        <FirstScreen />

        <SeoTextIntro />

        <SeoText />

        <Suspense>
          <Show when={landing() && canShowLanding()}>
            <section id="residences-eligibles">
              <LandingPrograms landing={landing()!} />
            </section>
          </Show>
        </Suspense>

        <div class="content-part with-overflow">
          <section id="budget-logement" class="form-in-page form-in-page-white">
            <form
              method="post"
              onInput={() => {
                debouncedCount(lotsSearchParams);
              }}
              onChange={() => {
                debouncedCount(lotsSearchParams);
              }}
              class="cogedim-access-search-form"
              data-test="search-mp-access"
            >
              <h2>Je transforme mon loyer en propriété</h2>
              <p class="catchline" data-test="catchline">
                Indiquez le budget que vous consacrez chaque mois pour vous
                loger et découvrez notre sélection d'appartements et maisons.
              </p>
              <AccessBudget
                name="access_mp_amount"
                defaultFormStorage={setLotsSearchParams}
                value={lotsSearchParams.access_mp_amount}
              />
              <div class="field-group">
                <Select
                  disabled={data.loading}
                  label="Où souhaitez-vous habiter ?"
                  name="geographies"
                  options={optionsGeographies()}
                  multiple
                  defaultFormStorage={setLotsSearchParams}
                  value={lotsSearchParams.geographies}
                />
                <Select
                  label="Nb de pièces"
                  name="rooms"
                  options={optionsRooms}
                  multiple
                  defaultFormStorage={setLotsSearchParams}
                  value={lotsSearchParams.rooms}
                />
              </div>
              <button
                type="button"
                class="btn"
                id="btn-search"
                onClick={getLots}
                data-test="btn-search"
                disabled={count() === 0}
              >
                <Show
                  when={
                    lotsSearchParams.geographies ||
                    lotsSearchParams.rooms ||
                    lotsSearchParams.access_mp_amount
                  }
                  fallback="Trouver mon logement"
                >
                  <Show when={count()} fallback="0 résultat pour ces choix">
                    {formatPlural(count()!, "résultat")}
                  </Show>
                </Show>
              </button>
              <Show when={import.meta.env.VITE_COG_DEBUG_FORMS === "1"} keyed>
                <DebugDumper
                  name="Access search form"
                  data={{
                    store: lotsSearchParams,
                  }}
                />
              </Show>
            </form>

            <Show when={store.displayedLots.length > 0}>
              <div class="sorts" data-test="sorts">
                <span class="legend" data-test="legend">
                  Trier par :
                </span>

                <button
                  onClick={() => {
                    setSearchParams({ page: undefined });
                    setStore("sort", "program_name");
                  }}
                  classList={{ active: store.sort === "program_name" }}
                  type="button"
                  data-test="sort-program"
                >
                  Résidences
                </button>

                <button
                  onClick={() => {
                    setSearchParams({ page: undefined });
                    setStore("sort", "postal_code");
                  }}
                  classList={{ active: store.sort === "postal_code" }}
                  type="button"
                  data-test="sort-postal_code"
                >
                  Département
                </button>

                <button
                  onClick={() => {
                    setSearchParams({ page: undefined });
                    setStore("sort", "city");
                  }}
                  classList={{ active: store.sort === "city" }}
                  type="button"
                  data-test="sort-city"
                >
                  Ville
                </button>

                <button
                  onClick={() => {
                    setSearchParams({ page: undefined });
                    setStore("sort", "rooms");
                  }}
                  classList={{ active: store.sort === "rooms" }}
                  type="button"
                  data-test="sort-rooms"
                >
                  Pièces
                </button>

                <button
                  onClick={() => {
                    setSearchParams({ page: undefined });
                    setStore("sort", "access_mp_amount_ascending");
                  }}
                  classList={{
                    active: store.sort === "access_mp_amount_ascending",
                  }}
                  type="button"
                  data-test="sort-mp-asc"
                >
                  Mensualité croissante
                </button>

                <button
                  onClick={() => {
                    setSearchParams({ page: undefined });
                    setStore("sort", "access_mp_amount_descending");
                  }}
                  classList={{
                    active: store.sort === "access_mp_amount_descending",
                  }}
                  type="button"
                  data-test="sort-mp-desc"
                >
                  Mensualité décroissante
                </button>
              </div>

              <div class="lots" data-test="lots">
                <For each={store.displayedLots}>
                  {(lot) => (
                    <Suspense
                      fallback={
                        <article class="lot loading-lot">
                          <div class="spinner">
                            <i aria-hidden="true" class="loading-spinner" />
                          </div>
                          Chargement…
                        </article>
                      }
                    >
                      <LotAccessMonthlySearchResult
                        program_nid={lot.program_nid}
                        lot_id={lot.id_pp_lot}
                      />
                    </Suspense>
                  )}
                </For>
              </div>

              <Show when={store.slices.length > 1}>
                <Pagination
                  currentPage={store.currentPage}
                  totalPages={store.slices.length}
                  url="/cogedim-pour-vous/pourquoi-choisir-cogedim/le-nouveau-neuf/cogedim-access/"
                  type="search"
                  scrollTo="#btn-search"
                />
              </Show>
            </Show>
          </section>
        </div>

        <SeoTextBis />

        <Suspense>
          <Show when={landing() && canShowLanding()}>
            <section id="accompagnement">
              <FormInPageWrapper landing={landing()!} />
            </section>
          </Show>
        </Suspense>
      </article>

      <Breadcrumb items={breadcrumbItems()} />
    </>
  );
}

type LotAccessMonthlySearchResultProps = {
  program_nid: string;
  lot_id: string;
};

function LotAccessMonthlySearchResult(
  props: LotAccessMonthlySearchResultProps,
) {
  const [, { sendClick, sendShowEvent }] = useEventsContext();
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();

  const [, { setLot }] = useLotActive();

  const [wrapper] = createResource(
    () => parseInt(props.program_nid),
    getWrapper,
  );

  const lot = () => {
    if (!wrapper() && !wrapper()?.lots) return undefined;
    return wrapper()?.lots.find((lot) => lot.lot_id === props.lot_id);
  };

  const priceGroup = createMemo(() => {
    if (!lot()) return undefined;
    const pricesGroup = getPricesAndReturnRates(wrapper()!, lot()!);
    return getCheapestPriceGroup(pricesGroup, [
      "vat_inc",
      "vat_inc_reduced",
      "vat_inc_brs",
      "vat_inc_mastered",
    ]);
  });

  return (
    <>
      <Suspense
        fallback={
          <article class="lot loading-lot">
            <div class="spinner">
              <i aria-hidden="true" class="loading-spinner" />
            </div>
            Chargement…
          </article>
        }
      >
        <Show when={lot()}>
          <article class="lot" data-test={`lot-${lot()!.number}`}>
            <div class="column-0" data-test="column-0">
              <Show when={lot()!.rooms}>
                <span class="rooms" data-test="rooms">
                  {formatPlural(lot()!.rooms!, "pièce")}
                </span>
              </Show>
              <Show when={lot()!.surface}>
                <span class="surface" data-test="surface">
                  {formatSurface(lot()!.surface!)}
                </span>
              </Show>
              <Show when={lot()!.floor}>
                <span
                  class="floor"
                  data-test="floor"
                  innerHTML={formatFloor(lot()!.floor!, "étage")}
                />
              </Show>
            </div>
            <div class="column-1" data-test="column-1">
              <Show when={lot()!.field_access_mp_amount}>
                <span class="monthly-payment" data-test="monthly-payment">
                  {formatPrice(lot()!.field_access_mp_amount!)} / mois
                </span>
              </Show>
              <span class="price-group" data-test={priceGroup()?.data_test}>
                ou <span class="price">{priceGroup()?.price}</span>{" "}
                <span class="label">({priceGroup()?.price_label})</span>
              </span>
              <Show when={lot()!.field_access_mp_legals}>
                <a
                  href={lot()!.field_access_mp_legals}
                  target="_blank"
                  data-test="legals"
                  class="legals-link"
                >
                  Voir les conditions
                </a>
              </Show>
            </div>
            <div class="column-2" data-test="column-2">
              <span class="number" data-test="number">
                Lot {lot()!.number}
              </span>
              <div class="details" data-test="details">
                <span class="orientation" data-test="orientation">
                  Expo. {lot()!.orientation}
                </span>
                <Show when={getOutdoorsForTypology(wrapper()!, lot()!)}>
                  <span class="outdoors" data-test="outdoors">
                    {getOutdoorsForTypology(wrapper()!, lot()!)}
                  </span>
                </Show>
              </div>
            </div>
            <div class="column-3" data-test="column-3">
              <span class="city" data-test="city">
                {wrapper()?.program.field_city} (
                {wrapper()?.program.field_postal_code!.substring(0, 2)})
              </span>
              <span class="program" data-test="program">
                Résidence {wrapper()?.program.title}
              </span>
              <span class="address" data-test="address">
                {wrapper()?.program.field_street}
              </span>
            </div>
            <div class="column-4" data-test="column-4">
              <button
                type="button"
                class="btn"
                data-test="cta-drawer"
                onClick={() => {
                  sendClick("btn-lot-details", "grid-dwell");
                  setLot!(wrapper()!, lot()!);
                }}
              >
                + d’infos
              </button>
              <button
                type="button"
                class="btn btn-contact"
                data-test="cta-advisor"
                onClick={(e) => {
                  sendShowEvent(`advisor`, e, {
                    nid: wrapper()!.program.drupal_internal__nid,
                  });
                  openModalForm!("advisor", {
                    wrapper: wrapper(),
                    lot: lot(),
                  });
                  setModalFormHeadline!("Contacter un conseiller");
                }}
              >
                Contact
              </button>
            </div>
          </article>
        </Show>
      </Suspense>
    </>
  );
}

function FormInPageWrapper(props: { landing: Landing }) {
  const hiddenFields = () => {
    return {
      base_email: utmStoreGet("utm_source"),
      campaign: utmStoreGet("utm_campaign"),
      source: utmStoreGet("utm_source"),
      medium: utmStoreGet("utm_medium"),
      term: utmStoreGet("utm_term"),
      content: utmStoreGet("utm_content"),
      node_nid: props.landing.drupal_internal__nid,
    };
  };

  return (
    <>
      <div class="node-landing">
        <section class="form-in-page-wrapper">
          <div class="form-in-page form-in-page-white" data-test="form-in-page">
            <Show when={props.landing.field_form?.field_title}>
              <h2>{props.landing.field_form!.field_title}</h2>
            </Show>
            <div data-ga-zone="form">
              <Show when={props.landing.field_form?.field_catchline}>
                <p class="catchline" data-test="catchline">
                  {props.landing.field_form!.field_catchline}
                </p>
              </Show>
              <FormInPage
                landing={props.landing}
                formConfig={props.landing.field_form}
                hiddenFields={{ form_id: "in-page", ...hiddenFields() }}
                formNameForEvent={getFormEventNameFromCrmObject(
                  props.landing.field_form!.field_crm_object,
                )}
                forceDestination="habiter"
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
