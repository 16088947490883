// Request types
import type { String } from "ts-toolbelt";
import type {
  LotAccessMonthlySearchResult,
  RegulationEntity,
} from "./drupal_jsonapi";

export type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};

export type DrupalSolidNotFound = {
  error: string;
  code: 404;
};

export type SubmissionValues = Record<string, FormValue>;
export type FormValue = string | undefined;
export type FormValueSetter = (name: string, value: FormValue) => void;

export type FetcherErrorResponse = { error: string; code: number };

export type MetaData = {
  cache?: CacheMetaData;
  // Is the response originating from the cache?
  fromCache?: boolean;
  // Is the content published?
  published?: boolean;
};

export type CacheMetaData = {
  tags: string[];
  contexts: string[];
  "max-age": number;
};

export type MenuItem = {
  name: string;
  sanitized: string;
  href: string;
  expanded: boolean;
  children?: MenuItem[];
};

export type MenuResponse = {
  meta: MetaData;
  data: MenuItem[];
};

export type DrupalPathResponse = {
  meta: MetaData;
  data: DrupalPathInfo;
};

export type RedirectResponse = {
  meta: MetaData;
  data: {
    redirect_destination: string;
    code: number;
  };
};

export type Settings = {
  phone_number: string;
  instant_callback: {
    on: boolean;
    hours: string;
  };
  capency_enabled: boolean;
  faq_enabled: boolean;
  sourdline_enabled: boolean;
  escda_enabled: boolean;
  cityscan_is_enabled: boolean;
  cityscan_client_key: string;
  regulations_tids: {
    tva_normale: number;
    tva_reduite: number;
    prix_maitrises: number;
    pinel: number;
    lmnp_vat_inc: number;
    lmnp_vat_ex: number;
    nue_propriete: number;
    brs: number;
    pinel_plus: number;
    patrimonial: number;
  };
  regulations: RegulationEntity[];
  zones_fiscales: {
    a: number;
    a_bis: number;
    b1: number;
    b2: number;
  };
  product_types: {
    habiter: number;
    demembrement: number;
    residence_tourisme_affaire: number;
    residence_etudiants: number;
    residence_cogedim_club: number;
    residence_seniors: number;
    residence_services: number;
    brs: number;
  };
  lot_types: {
    parking: number;
    appartement: number;
    maison: number;
    terrain: number;
    cellier: number;
    cave: number;
  };
  typologies: {
    terrain_a_batir: number;
  };
  sales_states_tids: {
    avant_premiere: number;
    en_lancement: number;
    en_travaux: number;
    pret_a_emmenager: number;
    stock_epuise: number;
    en_commercialisation: number;
    livraison_imminente: number;
  };
  program_types: {
    mixte: number;
    appartements: number;
    maisons: number;
    terrains: number;
  };
  marketing_segments: {
    mkg_seg_access: number;
    mkg_seg_invest: number;
    mkg_seg_signature: number;
  };
  marketing_segments_terms: {
    access: {
      name: string;
      field_logo_main: string;
      field_logo_variante_1: string;
    };
    invest: {
      name: string;
      field_logo_main: string;
      field_logo_variante_1: string;
    };
    signature: {
      name: string;
      field_logo_main: string;
      field_logo_variante_1: string;
    };
  };
  nouveau_neuf_enabled: boolean;
  pinel_autopromo_home_enabled: boolean;
  // TODO remove me when removing urlRs
  solid_config: {
    programs_enabled: boolean;
    search_enabled: boolean;
    landings_enabled: boolean;
    geographies_enabled: boolean;
    articles_enabled: boolean;
    journeys_enabled: boolean;
    plot_enabled: boolean;
    parkings_enabled: boolean;
    front_enabled: boolean;
    simulator_enabled: boolean;
    faq_enabled: boolean;
    enga_enabled: boolean;
    press_releases_enabled: boolean;
  };
  solid_black_listed_urls: string[];

  form_blueprint_bypass: boolean;
  form_leaflet_bypass: boolean;

  prix_encadres_override: number | false;

  ga_tracking_id: string;
  gtm_container_id: string;

  import_last_date: string;

  // Promo card
  promo_card: {
    title: string;
    body: string;
    url: string;
    img: string;
    disp_search: boolean;
    disp_geographies: boolean;
    disp_landings: boolean;
  };

  // Pitch Woodeum banner
  pitch: {
    pitch_disp: boolean;
    banner: string;
    urls: string;
  };

  enga_video_main?: string;

  // Headband
  headband: {
    body?: string;
    button_text?: string;
    button_url?: string;
    bg_color?: string;
    text_color?: string;
    disp_home: boolean;
    disp_contents: boolean;
    disp_programs: boolean;
    disp_cnat: never; // Deprecated
  };

  landing_access_nid?: number;
  cog_env: string;

  domains_allowed_to_open_forms: string[];
};

export enum RealEstateContext {
  Unknown = "unknown",
  Dwell = "dwell",
  Invest = "invest",
}

export type RsPathType = String.Replace<
  keyof Settings["solid_config"],
  "_enabled",
  ""
>;

export type DrupalPathInfo = {
  entity_type: "node" | "taxonomy_term";
  bundle: "program" | "geography" | "landing" | "landing_2" | "landing_3";
  status?: boolean;
  id: string;
  suffix?: string;
};

export type PriceField =
  | "price_vat_ex"
  | "price_vat_inc"
  | "price_vat_inc_reduced"
  | "price_vat_inc_brs"
  | "price_vat_inc_mastered"
  | "price_furniture_vat_ex";

export type ReturnRateField =
  | "return_rate_vat_ex"
  | "return_rate_vat_inc"
  | "return_rate_pinel";

export type VatRateField = "vat_rate";

export type LotNumericField = PriceField | ReturnRateField | VatRateField;

export type SaleState =
  | "Avant-première"
  | "En lancement"
  | "En commercialisation"
  | "En travaux"
  | "Livraison imminente"
  | "Prêt à emménager"
  | "Stock épuisé";

export type LotType = "Appartement" | "Maison" | "Terrain";

export type RoomsCount = "1" | "2" | "3" | "4" | "5";

export type LotAccessMonthlySearchResultResponse = {
  meta: MetaData;
  data: {
    lots: LotAccessMonthlySearchResult[];
  };
};

export type GtmVarsValues = Record<string, string | string[] | number | null>;
