import { createEffect, createSignal, onMount } from "solid-js";
import type { Purpose, Vendor } from "~/contexts/DidomiContext";
import { useDidomiContext } from "~/contexts/DidomiContext";
import { useSearchParams } from "@solidjs/router";
import { Link } from "@solidjs/meta";

interface DidomiInterface {
  getVendorById: (vendor: string) => string;
  getObservableOnUserConsentStatusForVendor: (vendor: string) => string;
  getUserConsentStatusForPurpose: (purpose: string) => string;
  openTransaction: () => void;
  getUserStatus: () => {
    purposes: {
      global: {
        enabled: string[];
      };
    };
    vendors: {
      global: {
        enabled: string[];
      };
    };
  };
}

declare global {
  interface Window {
    dataLayer?: unknown[];
    didomiOnReady?: unknown[];
    didomiEventListeners?: unknown[];

    Didomi: DidomiInterface;
  }
}

const watchedVendors: Vendor[] = ["c:youtube", "c:pianohybr-R3VKC2r4"] as const;
const watchedPurposes: Purpose[] = ["measure_content_performance"] as const;

const [fireDidomiSync, setFireDidomiSync] = createSignal(false);

const debugDidomi = function () {
  if (localStorage && localStorage.getItem("didomi:debug")) {
    return true;
  }
  return false;
};

export function Didomi() {
  const [searchParams] = useSearchParams();
  if (
    import.meta.env.VITE_COG_DIDOMI_ENABLED == 1 &&
    searchParams.no_didomi != "1"
  ) {
    onMount(() => {
      debugDidomi() && console.log("🍪 Didomi enabled");
      // On page load we fetch the content from Didomi
      window.didomiOnReady = window.didomiOnReady || [];
      window.dataLayer = window.dataLayer || [];
      window.didomiOnReady.push(function () {
        console.log("🍪 Didomi is ready");
        setFireDidomiSync(true);
      });

      // Setup performance consent
      window.didomiEventListeners = window.didomiEventListeners || [];
      window.didomiEventListeners.push({
        event: "consent.changed",
        listener: function (context: unknown) {
          debugDidomi() && console.log("🍪 Consent.changed", context);
          // Ajout pour src_force - Début
          const piano = didomiGetVendorStatus("c:pianohybr-R3VKC2r4");
          if (piano && shouldUpdateConsent()) {
            warnSoftDataLayer();
            const payload = {
              event: "consent.changed",
              pianoConsent: true,
              src_medium: searchParams.utm_medium || "",
              src_source: searchParams.utm_source || "",
              src_content: searchParams.utm_content || "",
              src_term: searchParams.utm_term || "",
              src_campaign: searchParams.utm_campaign || "",
            };

            debugDidomi() && console.log("📤 Sending to dataLayer", payload);
            window.dataLayer!.push(payload);
            // Save the last consent update time to localStorage
            localStorage.setItem("lastConsentUpdate", new Date().toISOString());
          }
          // Ajout pour src_force - Fin
        },
      });
    });
  }

  createEffect(() => {
    if (fireDidomiSync()) {
      synchronizeConsentsInStore();
    }
  });

  return (
    <>
      <Link rel="preconnect" href="https://sdk.privacy-center.org" />
    </>
  );
}

/**
 * Warns the user if window.dataLayer is not defined.
 */
function warnSoftDataLayer() {
  !window.dataLayer && console.log("⚠️ window.dataLayer is not defined");
}

function warnSoftDidomi() {
  !window.Didomi && console.log("⚠️ Didomi is not defined");
}

/**
 * Synchronize the consents from Didomi to the store.
 */
export function synchronizeConsentsInStore() {
  const [, { setVendorStatus, setPurposeStatus }] = useDidomiContext();
  debugDidomi() && console.log("🍪 Synchronizing consents in store");
  // For vendors
  for (const vendor of watchedVendors) {
    const consentStatus = didomiGetVendorStatus(vendor);
    setVendorStatus!(vendor, Boolean(consentStatus));
    debugDidomi() &&
      console.log(`🍪 ${consentStatus ? "✅" : "❌"} Vendor: ${vendor}`);
  }

  // For purposes
  for (const purpose of watchedPurposes) {
    const consentStatus = didomiGetPurposeStatus(purpose);
    setPurposeStatus!(purpose, Boolean(consentStatus));
    debugDidomi() &&
      console.log(`🍪 ${consentStatus ? "✅" : "❌"} Purpose: ${purpose}`);
  }
}

/**
 * Returns true if the consent should be updated.
 */
const shouldUpdateConsent = () => {
  const lastUpdate = localStorage.getItem("lastConsentUpdate");
  if (!lastUpdate) {
    return true;
  }

  const lastUpdateDate = new Date(lastUpdate);
  const now = new Date();
  const thirtyMinutesInMilliseconds = 30 * 60 * 1000;

  return (
    now.getTime() - lastUpdateDate.getTime() >= thirtyMinutesInMilliseconds
  );
};

export function didomiEnableVendor(vendor: Vendor) {
  warnSoftDidomi();
  if (window.Didomi) {
    const y = window.Didomi.getVendorById(vendor);
    // @ts-expect-error no type for openTransaction
    const ppIDs = y.purposeIds;
    const t = window.Didomi.openTransaction();
    // @ts-expect-error no type for openTransaction
    t.enableVendor(y.id);

    for (const ppID of ppIDs) {
      // @ts-expect-error no type for openTransaction
      t.enablePurpose(ppID);
    }

    // @ts-expect-error no type for openTransaction
    t.commit();
  }
}

export function didomiDisableVendor(vendor: Vendor) {
  warnSoftDidomi();
  if (window.Didomi) {
    const t = window.Didomi.openTransaction() as unknown;
    const y = window.Didomi.getVendorById(vendor) as unknown;
    // @ts-expect-error no type for openTransaction
    t.disableVendor(y.id);
    // @ts-expect-error no type for openTransaction
    t.commit();
  }
}

export function didomiEnablePurpose(purpose: Purpose) {
  warnSoftDidomi();
  const t = window.Didomi.openTransaction() as unknown;
  // @ts-expect-error no type for openTransaction
  t.enablePurposes(purpose);
  // @ts-expect-error no type for openTransaction
  t.commit();
}

export function didomiGetPurposeStatus(purpose: Purpose) {
  return window.Didomi.getUserStatus().purposes.global.enabled.includes(
    purpose,
  );
}

export function didomiGetVendorStatus(vendor: Vendor) {
  return window.Didomi.getUserStatus().vendors.global.enabled.includes(vendor);
}
